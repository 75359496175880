<template>
  <div
    ref="mutiInput"
    class="com-contanier"
    :class="[enter, focus]"
    @mouseenter="enter = 'enter'"
    @mouseleave="enter = ''"
  >
    <div v-for="(item, $index) in valueArr" :key="$index" class="panel">
      <div class="label">{{ $index + 1 }}</div>
      <el-input
        v-model="valueArr[$index]"
        :disabled="$index !== valueArr.length - 1"
        placeholder="请输入(以逗号，空格或;隔开)"
        class="item-input"
        @change="change"
        @focus="focus = 'focus'"
        @blur="focus = ''"
      />
      <div class="opt">
        <i
          v-show="$index !== valueArr.length - 1"
          class="el-icon-error icon"
          title="删除"
          @click.stop="removeRow($index)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  value: {
    type: [String, Array],
    required: true
  },
  data() {
    return {
      valueArr: [''],
      enter: '',
      focus: ''
    }
  },
    watch: {
      value: {
          handler(val) {
              console.log('val', val)
              if (val) {
                  this.valueArr = val
              } else {
                  this.valueArr = ['']
              }
          },
          immediate: true,
          deep: true
      }
    },
  methods: {
    change(val) {
      this.formatter(val)
    },
    reset() {
      this.valueArr = ['']
    },
    formatter(val) {
      const arr = val.split(/[;, ]/)
      console.log('arr', arr)
      arr.forEach((item, $index) => {
        if (item) {
          if ($index === 0) {
            this.valueArr[this.valueArr.length - 1] = item
          } else {
            this.valueArr.push(item)
          }
        }
      })
      this.emitValue()
      this.valueArr.push('')
    },
    emitValue() {
      const arr = this.valueArr.map((item) => {
        if (item) {
          return item
        }
      })
      console.log(arr)
      this.$emit('input', arr)
    },
    removeRow($index) {
      this.valueArr.splice($index, 1)
      const arr = this.valueArr.map((item) => item)
      const newArr = arr.length === 1 && arr[0] === '' ? [] : arr
      this.$emit('input', newArr)
    }
  }
}
</script>
<style lang="less" scoped>
.com-contanier {
  width: 100%;
  min-height: 38px;
  max-height: 224px;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  &::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #F7F7F7;
  }
  &::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #CDCDCD;
    border: 1px solid transparent;
    border-radius: 50px;
    background-clip: content-box;
  }
  &.enter {
    border-color: #c0c4cc;
  }
  &.focus {
    border-color: #409EFF;
  }
  .panel {
    width: 100%;
    height: 38px;
    display: flex;
    overflow: hidden;
    &:nth-child(2n) {
      background: #f7f7f7;
      &:deep(.el-input__inner) {
        border: 0;
        background: #f7f7f7;
      }
    }
  }
  .label {
    width: 40px;
    height: 38px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 38px;
    text-align: center;
  }
  &:deep(.el-input__inner) {
    border: 0;
  }
  .icon {
    color: #CDCDCD;
    font-size: 14px;
    line-height: 38px;
    cursor: pointer;
  }
  .item-input {
    width: calc(100% - 80px);
  }
  .opt {
    width: 40px;
    text-align: center;
  }
  &:deep(.el-input.is-disabled .el-input__inner) {
    background: transparent;
  }
}
</style>
